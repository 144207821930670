import commonHelper from '@/app/utility/common.helper.utility';
import purReqEntryForm from './purReqEntryForm';
import DatePicker from 'vue2-datepicker';
import appStrings from '@/app/utility/string.utility';
import purchaseReqVset from './purchaseReqVset';
import HistoryRecord from '@/app/components/onlineApproval/internalWorkFlow/history/historyRecord';
import ApprovalHistory from '@/app/components/onlineApproval/internalWorkFlow/history/approvalHistory';
import UploadDocument from '@/app/components/onlineApproval/internalWorkFlow/history/uploadDocument';
import sendForApproval from '@/app/components/onlineApproval/internalWorkFlow/history/sendForApproval';

export default {
  name: 'purchaseRequisition',
  watch: {
    currentPage: function() {
      this.getPurReqDtls();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getPurReqDtls();
    }
  },
  components: {
    purReqEntryForm,
    DatePicker,
    purchaseReqVset,
    HistoryRecord,
    ApprovalHistory,
    sendForApproval,
    UploadDocument
  },
  data() {
    return {
      loader: false,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      totalRows: null,
      currentPage: 1,
      operationUnit: {
        text: null,
        value: null
      },
      requiNum: null,
      preparer: null,
      buyer: null,
      requester: null,
      item: {
        text: null,
        value: null
      },
      status: {
        text: null,
        value: null
      },
      postOracleStatus: {
        text: null,
        value: null
      },
      dateFrom: null,
      dateTo: null,
      purchaseRequisitionData: [],
      purchaseRequisitionFields: [
        {
          key: 'select',
          variant: 'info',
          stickyColumn: true
        },
        {
          key: 'OU_NAME',
          label: 'Operating Unit',
          class: 'text-center'
        },
        {
          key: 'REQUISITION_NUMBER',
          label: 'Requisition Number',
          class: 'text-center'
        },
        {
          key: 'PREPARER_NAME',
          label: 'Preparer',
          class: 'text-center'
        },
        {
          key: 'REQUISITION_TYPE',
          label: 'Requisition Type',
          class: 'text-center'
        },
        {
          key: 'APPROVAL_STATUS',
          label: 'Approval Status',
          class: 'text-center'
        },
        {
          key: 'DESCRIPTION',
          label: 'Description',
          class: 'text-center'
        },
        {
          key: 'CREATED_BY',
          label: 'Created By',
          class: 'text-center'
        },
        {
          key: 'CREATION_DATE',
          label: 'Creation Date',
          class: 'text-center'
        },
        {
          key: 'POST_TO_ORACLE_STATUS_MEANING',
          label: 'Post To Oracle Status',
          class: 'text-center'
        },
        {
          key: 'CURRENCY',
          label: 'Currency',
          class: 'text-center'
        }
      ],
     
      showPurReqEntryModal: false,
      accessButtons: {
        deleteAllow: false,
        downloadAllow: false,
        uploadAllow: false,
        editAllow: true,
        addAllow: true,
        printAllow: false,
        approvalAllow: false
      },
      unsubscribe: null,
      showValueSetModal: false,
      rowData: null,
      showPurReqVsetModal: false,
      openModalFormType: 'purReqMainForm',
      vsetName: null,
      openModalType: null,
      showHistoryBtn: false,
      selectedReqDtls: null,
      showHistory: false,
      historyType: null,
      showOpenKmModal: false,
      openkmAuthToken: null,
      showApproveRejectModal: false,
      approvalReason: null,
      rejectedReason: null,
      approvalRemarks: null,
      rejectionRemarks: null,
      apprStatus: ''
    };
  },
  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'add') {
          this.rowData = null;
          this.showPurReqEntryModal = true;
        }
      }
    });
  },
  methods: {
    showHidePurReqEntryModal(flag) {
      this.showPurReqEntryModal = flag;
    },
    disabledDates(date) {
      return commonHelper.disabledDates(date, this.dateFrom);
    },
    selectedRequisiton(item) {
      this.selectedReqDtls = Object.assign({}, item);
      this.showHistoryBtn = true;
    },
    showHideHistory(flag, historyType) {
      this.showHistory = flag;
      this.historyType = historyType;
      if (!flag) {
        this.getPurReqDtls();
      }
    },
    validateUploadDocument() {
      this.showHideHistory(true, 'sendForApproval');
    },
    openValueSetModal(vsetCode) {
      this.vsetCode = vsetCode;
      this.parent_value_set_id = null;
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      if (
        this.vsetCode === appStrings.VALUESETTYPE.PARTY_REQ_APPROVAL_STATUS_VSET
      ) {
        this.status = {
          text: item.value_meaning,
          value: item.value_code
        };
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.PURCHASE_REQ_STATUS
      ) {
        this.postOracleStatus = {
          text: item.value_meaning,
          value: item.value_code
        };
      }
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    clearVsetValues(vsetCode) {
      if (vsetCode === this.operationUnit.value) {
        this.operationUnit = {
          text: null,
          value: null
        };
      } else if (vsetCode === this.requiNum) {
        this.requiNum = null;
      } else if (vsetCode === this.preparer) {
        this.preparer = null;
      } else if (vsetCode === this.buyer) {
        this.buyer = null;
      } else if (vsetCode === this.requester) {
        this.requester = null;
      } else if (vsetCode === this.item.value) {
        this.item = {
          text: null,
          value: null
        };
      } else if (vsetCode === this.status.value) {
        this.status = {
          text: null,
          value: null
        };
      } else if (vsetCode === this.postOracleStatus.value) {
        this.postOracleStatus = {
          text: null,
          value: null
        };
      }
    },
    getPurReqDtls() {
      // this.$v.$touch();
      // if (!this.$v.$invalid) {
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        user_id: this.$store.state.auth.userId,
        requisition_number: this.requiNum,
        org_id: this.operationUnit.value,
        operating_unit: this.operationUnit.text,
        approval_status: this.status.value,
        preparer: this.preparer,
        post_to_oracle_status: this.postOracleStatus.value,
        creation_date_from: this.dateFrom,
        creation_date_to: this.dateTo
      };
      this.loader = true;
      this.$store
        .dispatch('purchaseRequisition/getPurReqDtls', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data.page;
            this.purchaseRequisitionData = result;
            this.totalRows = resp.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
      // }
    },
    clear() {
      this.operationUnit = {
        text: null,
        value: null
      };
      this.requiNum = null;
      this.preparer = null;
      this.status = {
        text: null,
        value: null
      };
      this.postOracleStatus = {
        text: null,
        value: null
      };
      this.dateFrom = null;
      this.dateTo = null;
      this.showHistoryBtn = false;
      this.totalRows = null;
      this.purchaseRequisitionData = [];
    },
    openVsetModal(type) {
      if (type === 'operationgUnit') {
        this.vsetName = 'Operating Unit Details';
        this.openModalType = 'operatingUnit';
        this.showHidePurReqVsetModal(true);
      }
    },
    showHidePurReqVsetModal(flag) {
      this.showPurReqVsetModal = flag;
    },
    mapSelectedVsetData(item) {
      if (item.name === 'purReqOU') {
        this.operationUnit = {
          text: item.ou_name,
          value: item.ou_id
        };
      }
      this.showPurReqVsetModal = false;
    },
    rowSelected(item) {
      this.rowData = item;
      this.apprStatus = item.APPROVAL_STATUS;
      this.selectedReqDtls = Object.assign({}, item);
      this.showHidePurReqEntryModal(true);
    },
    createReqWithCopyData() {
      const copyPrompt = confirm(
        'Are you sure you want to copy the requisition?'
      );
      if (copyPrompt) {
        this.getPurReqDtlsById();
      }
    },
    getPurReqDtlsById() {
      const payload = {
        REQUISITION_HEADER_ID: this.selectedReqDtls.REQUISITION_HEADER_ID,
        user_id: this.$store.state.auth.userId
      };
      this.loader = true;
      this.$store
        .dispatch('purchaseRequisition/getPurReqDtlsById', payload)
        .then(response => {
          this.loader = false;
          this.editMode = false;
          if (response.status === 200) {
            const result = response.data.data;
            this.addEditPurReqDtls(result);
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    addEditPurReqDtls(copyData) {
      const data = copyData.line_dtls.map(ele => {
        return {
          requisition_line_id: 0,
          line_type_id: ele.LINE_TYPE_ID,
          line_type: ele.LINE_TYPE,
          item_code: ele.ITEM_CODE,
          item_id: ele.ITEM_ID,
          item_category: ele.ITEM_CATEGORY,
          category_id: ele.CATEGORY_ID,
          description: ele.ITEM_DESCRIPTION,
          primary_uom_code: ele.PRIMARY_UOM_CODE,
          unit_of_measure: ele.UNIT_MEAS_LOOKUP_CODE,
          destination_type_code: ele.DESTINATION_TYPE_CODE,
          destination_type_meaning: ele.DESTINATION_TYPE_CODE,
          price: +ele.UNIT_PRICE,
          quantity: +ele.QUANTITY,
          need_by_date: ele.NEED_BY_DATE,
          buyer_id: ele.SUGGESTED_BUYER_ID,
          buyer_name: ele.SUGGESTED_BUYER_NAME,
          requested_by: ele.REQUESTED_BY,
          cost_center: ele.COST_CENTER,
          requester_id: ele.REQUESTER_ID,
          requester_name: ele.REQUESTER_NAME,
          requester_email: ele.REQUESTER_EMAIL,
          organization_code: ele.ORGANIZATION_CODE,
          organization_name: ele.ORGANIZATION_NAME,
          location_id: ele.DELIVER_TO_LOCATION_ID,
          location: ele.LOCATION,
          distribution_details: {
            distribution_id: 0,
            charge_account_id: ele.distribution_dtls.CHARGE_ACCOUNT_ID,
            charge_account_desciption:
              ele.distribution_dtls.CHARGE_ACCOUNT_DESCRIPTION,
            accural_account_id: ele.distribution_dtls.ACCRUAL_ACCOUNT_ID,
            accural_account_description:
              ele.distribution_dtls.ACCRUAL_ACCOUNT_DESCRIPTION,
            varrience_account_id: ele.distribution_dtls.VARIANCE_ACCOUNT_ID,
            varrience_account_description:
              ele.distribution_dtls.VARIANCE_ACCOUNT_DESCRIPTION
          },
          supplier_id: ele.VENDOR_ID,
          supplier_name: ele.SUGGESTED_VENDOR_NAME,
          supplier_site_id: ele.VENDOR_SITE_ID,
          supplier_site_name: ele.SUGGESTED_VENDOR_LOCATION,
          contact_person: ele.SUGGESTED_VENDOR_CONTACT,
          phone: ele.SUGGESTED_VENDOR_PHONE
        };
      });
      const payload = {
        purchasing_input_parameter: {
          purchasing_header_details: {
            requisition_header_id: 0,
            ou_id: copyData.ORG_ID,
            ou_name: copyData.OU_NAME,
            description: copyData.DESCRIPTION,
            requisition_type: copyData.REQUISITION_TYPE,
            status: 'DRAFT',
            preparer_id: this.$store.state.auth.userId,
            preparer_name: this.$store.state.auth.userName,
            amount: copyData.AMOUNT,
            currency: copyData.CURRENCY,
            purchasing_lines_details: data
          }
        }
      };
      this.loader = true;
      this.$store
        .dispatch('purchaseRequisition/addEditPurReqDtls', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.editMode = false;
            this.getPurReqDtls();
            this.$bvToast.toast(response.data.message, {
              title: 'Alert',
              variant: 'success',
              solid: true
            });
          } else {
            this.editMode = true;
            this.$bvToast.toast(response.response.data.message, {
              title: 'Alert',
              variant: 'danger',
              solid: true
            });
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    checkOpenKmPasswordSaved() {
      // to check password save or not
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/checkSavedPassword')
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            if (response.data.data) {
              this.getOpenKmAuthToken();
            } else {
              this.showHideOpenKmModal(true);
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getOpenKmAuthToken() {
      // to get auth token for open km
      const payload = {
        password: null,
        saved_password_flag: true,
        user_name: null
      };
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/openKmAuthenticate', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.openkmAuthToken = response.data.data.token;
            this.showHideHistory(true, 'uploadDocument');
          }
        })
        .catch(() => {
          this.loader = false;
        });
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};

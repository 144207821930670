import DatePicker from 'vue2-datepicker';
import purchaseReqVset from '../purchaseReqVset';
import { format } from 'date-fns';
import commonHelper from '@/app/utility/common.helper.utility';

export default {
  name: 'purReqEntryForm',
  props: ['rowData', 'wfPurReqDetails'],
  watch: {},
  components: {
    DatePicker,
    purchaseReqVset
  },
  data() {
    return {
      loader: false,
      editMode: false,
      operatingUnit: {
        text: null,
        value: null
      },
      number: null,
      description: null,
      reqHdrType: {
        text: 'Purchase Requisition',
        value: 'PURCHASE'
      },
      status: {
        text: 'DRAFT',
        value: 'DRAFT'
      },
      preparer: null,
      preparerId: null,
      currency: 'INR',
      totalAmt: 0,
      creationDate: format(new Date(), 'dd-MMM-yyyy'),
      lineDtlsData: [
        {
          num: 1,
          line_type: 'Goods',
          line_type_Code: 1,
          item: '',
          category: null,
          line_desc: null,
          uom: null,
          price: 0,
          need_by_date: null,
          quantity: 0,
          charge_acc: null,
          buyer: null,
          req_by: null,
          cost_center: null,
          requester: null,
          requester_email: null,
          organization: null,
          location: null,
          supplier: null,
          site: null,
          contact_person: null,
          phone_number: null,
          requisition_line_id: 0
        }
      ],
      lineDtlsFields: [
        {
          key: 'add',
          stickyColumn: true,
          class: 'text-center'
        },
        {
          key: 'remove',
          stickyColumn: true,
          class: 'text-center'
        },
        {
          key: 'num',
          label: 'Num',
          class: 'text-center'
        },
        {
          key: 'line_type',
          label: 'Line Type',
          class: 'text-center'
        },
        {
          key: 'item',
          label: 'Item',
          class: 'text-center'
        },
        {
          key: 'category',
          label: 'Category',
          class: 'text-center'
        },
        {
          key: 'line_desc',
          label: 'Description',
          class: 'text-center'
        },
        {
          key: 'uom',
          label: 'UOM',
          class: 'text-center'
        },
        {
          key: 'cost_center',
          label: 'Cost Center',
          class: 'text-center'
        },
        {
          key: 'organization',
          label: 'Organization',
          class: 'text-center'
        },
        // {
        //   key: 'location',
        //   label: 'Location',
        //   class: 'text-center'
        // },
        {
          key: 'quantity',
          label: 'Quantity',
          class: 'text-center'
        },
        {
          key: 'price',
          label: 'Price',
          class: 'text-center'
        },
        {
          key: 'need_by_date',
          label: 'Need By Dtae',
          class: 'text-center'
        },
        {
          key: 'buyer',
          label: 'Buyer',
          class: 'text-center'
        },
        // {
        //   key: 'dest_type',
        //   label: 'Destination Type',
        //   class: 'text-center'
        // },
        // {
        //   key: 'charge_acc',
        //   label: 'Charge Account',
        //   class: 'text-center'
        // },
        {
          key: 'req_by',
          label: 'Requested By',
          class: 'text-center'
        },
        {
          key: 'requester',
          label: 'Requester',
          class: 'text-center'
        },
        {
          key: 'requester_email',
          label: 'Requester Email',
          class: 'text-center'
        },
        {
          key: 'supplier',
          label: 'Supplier',
          class: 'text-center'
        },
        {
          key: 'site',
          label: 'Site',
          class: 'text-center'
        },
        {
          key: 'contact_person',
          label: 'Contact Person',
          class: 'text-center'
        },
        {
          key: 'phone_number',
          label: 'Phone Number',
          class: 'text-center'
        },
        {
          key: 'post_oracle_status',
          label: 'Post To Oracle Status',
          class: 'text-center'
        },
        {
          key: 'post_oracle_message',
          label: 'Post To Oracle Message',
          class: 'text-center'
        }
      ],
      // supplierDtls: {
      //   // source: null,
      //   supplier: { text: null, value: null },
      //   site: { text: null, value: null },
      //   contact: null,
      //   phone: null
      // },
      vsetName: null,
      reqHdrId: null,
      openModalFormType: 'purReqEntryForm',
      openModalType: null,
      sepctIndex: null,
      showPurReqVsetModal: false,
      reqKeyFilter: {},
      destinationType: {
        text: null,
        value: null
      },
      unsubscribe: null,
      regexPattern: /^[a-zA-Z0-9._%+-]+@delhivery\.com$/,
      emailFlag: false,
      // nullDate: 0,
      wfApprovalStatus: null,
      approvalStatusKey: null,
      approvRejectType: null,
      approvalReason: null,
      rejectedReason: null,
      approvalRemarks: null,
      rejectionRemarks: null,
      showApproveRejectModal: false,
      initialLineDtls: [],
      itemTypeList: [
        'Capital Goods',
        'Service items',
        'Inventory Items',
        'Expense',
        'Others'
      ],
      selectedItemType: ''
    };
  },
  mounted() {
    this.preparer = this.$store.state.auth.userName;
    this.preparerId = this.$store.state.auth.userId;
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (
          actionName === 'save' &&
          (this.status.text === 'DRAFT' || this.status.text === 'REJECTED')
        ) {
          this.addEditPurReqDtls();
        }
        if (actionName === 'update') {
          this.editMode = true;
        }
      }
    });
    if (!this.rowData) {
      this.editMode = true;
      if (this.status.text === 'DRAFT') {
        this.lineDtlsFields = this.lineDtlsFields.filter(
          field =>
            field.key !== 'post_oracle_status' &&
            field.key !== 'post_oracle_message'
        );
      }
    } else {
      this.reqHdrId = this.rowData.REQUISITION_HEADER_ID;
      this.getPurReqDtlsById();
    }
    if (
      (this.rowData && this.rowData.APPROVAL_STATUS !== 'DRAFT') ||
      (this.wfPurReqDetails && this.wfPurReqDetails.approval_status !== 'DRAFT')
    ) {
      this.lineDtlsFields.splice(0, 2);
    }
    if (this.wfPurReqDetails) {
      // from dashboard
      this.reqHdrId = this.wfPurReqDetails.requisition_header_id;
      this.getPurReqDtlsById(this.reqHdrId);
    }
  },
  methods: {
    clearVsetValues(vsetCode, index) {
      if (vsetCode === this.operatingUnit.text) {
        this.operatingUnit = {
          text: null,
          value: null
        };
      } else if (vsetCode === this.number) {
        this.number = null;
      } else if (vsetCode === this.description) {
        this.description = null;
      } else if (vsetCode === this.reqHdrType.text) {
        this.reqHdrType = {
          text: null,
          valye: null
        };
      } else if (vsetCode === this.preparer) {
        this.preparer = null;
      } else if (vsetCode === this.status.value) {
        this.status = {
          text: null,
          value: null
        };
      } else if (vsetCode === 'requester') {
        this.lineDtlsData[index].requester = null;
        this.lineDtlsData[index].requestorCode = null;
        this.lineDtlsData[index].requester_email = null;
        this.lineDtlsData[index].reqEmailCode = null;
      } else if (vsetCode === 'reqEmail') {
        this.lineDtlsData[index].requester_email = null;
        this.lineDtlsData[index].reqEmailCode = null;
      } else if (vsetCode === 'buyer') {
        this.lineDtlsData[index].buyer = null;
        this.lineDtlsData[index].buyerCode = null;
      } else if (vsetCode === 'category') {
        this.lineDtlsData[index].category = null;
        this.lineDtlsData[index].categoryCode = null;
      } else if (vsetCode === 'supplier') {
        this.lineDtlsData[index].supplier = null;
        this.lineDtlsData[index].supplierCode = null;
        this.lineDtlsData[index].site = null;
        this.lineDtlsData[index].siteCode = null;
        this.lineDtlsData[index].contact_person = null;
        this.lineDtlsData[index].phone_number = null;
      } else if (vsetCode === 'suppSite') {
        this.supplierDtls.site = {
          text: null,
          value: null
        };
        this.supplierDtls.contact_person = null;
        this.supplierDtls.phone_number = null;
      } else if (vsetCode === 'costCenter') {
        this.lineDtlsData[index].cost_center = null;
        this.lineDtlsData[index].location = null;
        this.lineDtlsData[index].locationCode = null;
        this.lineDtlsData[index].organization = null;
        this.lineDtlsData[index].orgCode = null;
      } else if (vsetCode === 'organization') {
        this.lineDtlsData[index].organization = null;
        this.lineDtlsData[index].orgCode = null;
      } else if (vsetCode === 'location') {
        this.lineDtlsData[index].location = null;
        this.lineDtlsData[index].locationCode = null;
      } else if (vsetCode === 'reqLineType') {
        this.lineDtlsData[index].line_type = null;
        this.lineDtlsData[index].line_type_Code = null;
      } else if (vsetCode === 'invItem') {
        this.lineDtlsData[index].categoryCode = null;
        this.lineDtlsData[index].category = null;
        this.lineDtlsData[index].item = '';
        this.lineDtlsData[index].itemCode = null;
        this.lineDtlsData[index].uom = null;
        this.lineDtlsData[index].uom_meaning = null;
        this.lineDtlsData[index].line_desc = null;
        this.lineDtlsData[index].cost_center = null;
        this.lineDtlsData[index].price = null;
        this.lineDtlsData[index].location = null;
        this.lineDtlsData[index].locationCode = null;
        this.lineDtlsData[index].organization = null;
        this.lineDtlsData[index].orgCode = null;
        this.destinationType = {
          text: null,
          value: null
        };
      } else if (vsetCode === 'chargeAcc') {
        this.lineDtlsData[index].charge_acc = null;
        this.lineDtlsData[index].charge_acc_code = null;
      }
    },
    addNewRow() {
      this.lineDtlsData.push({
        num: this.lineDtlsData.length + 1,
        line_type: 'Goods',
        line_type_Code: 1,
        item: '',
        category: null,
        line_desc: null,
        uom: null,
        price: '0',
        need_by_date: null,
        quantity: '0',
        charge_acc: null,
        buyer: null,
        req_by: null,
        cost_center: null,
        requester: null,
        requester_email: null,
        organization: null,
        location: null,
        supplier: null,
        site: null,
        contact_person: null,
        phone_number: null,
        currDate: format(new Date(), 'dd-MMM-yyyy'),
        requisition_line_id: 0
      });
    },
    openVsetModal(type, index) {
      this.sepctIndex = index;
      if (type === 'operationgUnit') {
        this.vsetName = 'Operating Unit Details';
        this.openModalType = 'operatingUnit';
        this.showHidePurReqVsetModal(true);
      } else if (type === 'requester') {
        this.vsetName = 'Requester Details';
        this.openModalType = 'requester';
        this.showHidePurReqVsetModal(true);
      } else if (type === 'reqEmail') {
        this.vsetName = 'Requester Email Details';
        this.openModalType = 'reqEmail';
        this.showHidePurReqVsetModal(true);
      } else if (type === 'buyer') {
        this.vsetName = 'Buyer Details';
        this.openModalType = 'buyer';
        this.showHidePurReqVsetModal(true);
      } else if (type === 'category') {
        this.vsetName = 'Categories Details';
        this.openModalType = 'category';
        this.showHidePurReqVsetModal(true);
      } else if (type === 'supplier') {
        if (this.operatingUnit.text) {
          this.vsetName = 'Supplier Details';
          this.openModalType = 'supplier';
          this.reqKeyFilter.ouId = this.operatingUnit.value;
          this.showHidePurReqVsetModal(true);
        } else {
          this.$bvToast.toast('Please Select Operating Unit first', {
            title: 'Alert',
            variant: 'danger',
            solid: true
          });
        }
      } else if (type === 'invItem') {
        if (this.operatingUnit.text && this.selectedItemType) {
          this.vsetName = 'Inventory Item Details';
          this.openModalType = 'invItem';
          this.reqKeyFilter.ouId = this.operatingUnit.value;
          this.reqKeyFilter.itemType = this.selectedItemType;
          this.showHidePurReqVsetModal(true);
        } else {
          if (this.operatingUnit.text && !this.selectedItemType) {
            this.$bvToast.toast('Please Select Item Type first', {
              title: 'Alert',
              variant: 'danger',
              solid: true
            });
          } else if (!this.operatingUnit.text && this.selectedItemType) {
            this.$bvToast.toast('Please Select Operating Unit first', {
              title: 'Alert',
              variant: 'danger',
              solid: true
            });
          } else {
            this.$bvToast.toast(
              'Please Select Operating Unit and Item Type first',
              {
                title: 'Alert',
                variant: 'danger',
                solid: true
              }
            );
          }
        }
      } else if (type === 'suppSite') {
        if (this.lineDtlsData[index].supplier) {
          this.vsetName = 'Supplier Site Details';
          this.openModalType = 'supplierSite';
          this.reqKeyFilter.vendorId = this.lineDtlsData[index].supplierCode;
          this.showHidePurReqVsetModal(true);
        } else {
          this.$bvToast.toast('Please select supplier first', {
            title: 'Alert',
            variant: 'danger',
            solid: true
          });
        }
      } else if (type === 'costCenter') {
        if (this.lineDtlsData[index].item) {
          this.vsetName = 'Cost Center Details';
          this.openModalType = 'costCenter';
          this.reqKeyFilter.ouId = this.operatingUnit.value;
          this.showHidePurReqVsetModal(true);
        } else {
          this.$bvToast.toast('Please select item first', {
            title: 'Alert',
            variant: 'danger',
            solid: true
          });
        }
      } else if (type === 'organization') {
        if (this.lineDtlsData[index].cost_center) {
          this.vsetName = 'Organization Details';
          this.openModalType = 'organization';
          this.reqKeyFilter.cost_center = this.lineDtlsData[index].cost_center;
          this.showHidePurReqVsetModal(true);
        } else {
          this.$bvToast.toast('Please select cost center first', {
            title: 'Alert',
            variant: 'danger',
            solid: true
          });
        }
      } else if (type === 'location') {
        if (this.lineDtlsData[index].cost_center) {
          this.vsetName = 'Location Details';
          this.openModalType = 'location';
          this.reqKeyFilter.cost_center = this.lineDtlsData[index].cost_center;
          this.showHidePurReqVsetModal(true);
        } else {
          this.$bvToast.toast('Please select cost center first', {
            title: 'Alert',
            variant: 'danger',
            solid: true
          });
        }
      } else if (type === 'reqLineType') {
        this.vsetName = 'Requisition Line Type Details';
        this.openModalType = 'reqLineType';
        this.showHidePurReqVsetModal(true);
      } else if (type === 'reqHdrType') {
        this.vsetName = 'Requisition Type Details';
        this.openModalType = 'reqHdrType';
        this.showHidePurReqVsetModal(true);
      } else if (type === 'chargeAcc') {
        if (this.lineDtlsData[index].orgId) {
          this.vsetName = 'Charge Account Details';
          this.openModalType = 'chargeAcc';
          this.reqKeyFilter.orgId = this.lineDtlsData[index].orgId;
          this.reqKeyFilter.itemId = this.lineDtlsData[index].itemCode;
          this.reqKeyFilter.chargeAccType = this.lineDtlsData[index].dest_type;
          this.showHidePurReqVsetModal(true);
        } else {
          this.$bvToast.toast('Please select cost center first', {
            title: 'Alert',
            variant: 'danger',
            solid: true
          });
        }
      }
    },
    showHidePurReqVsetModal(flag) {
      this.showPurReqVsetModal = flag;
    },
    mapSelectedVsetData(item) {
      if (item.name === 'purReqOU') {
        this.operatingUnit = {
          text: item.ou_name,
          value: item.ou_id
        };
      } else if (item.name === 'purRequester') {
        this.lineDtlsData[this.sepctIndex].requester_email = null;
        this.lineDtlsData[this.sepctIndex].reqEmailCode = null;
        this.lineDtlsData[this.sepctIndex].requester = item.global_name;
        this.lineDtlsData[this.sepctIndex].requestorCode = item.person_id;
        this.getReqEmailData();
        this.lineDtlsData = JSON.parse(JSON.stringify(this.lineDtlsData));
        // } else if (item.name === 'purReqEmail') {
        //   this.lineDtlsData[this.sepctIndex].requester_email = item.email_address;
        //   this.lineDtlsData[this.sepctIndex].reqEmailCode = item.person_id;
        //   this.lineDtlsData = JSON.parse(JSON.stringify(this.lineDtlsData));
      } else if (item.name === 'purReqBuyer') {
        this.lineDtlsData[this.sepctIndex].buyer = item.global_name;
        this.lineDtlsData[this.sepctIndex].buyerCode = item.agent_id;
        this.lineDtlsData = JSON.parse(JSON.stringify(this.lineDtlsData));
      } else if (item.name === 'purReqCategory') {
        this.lineDtlsData[this.sepctIndex].category = item.item_category;
        this.lineDtlsData[this.sepctIndex].categoryCode = item.category_id;
        this.lineDtlsData = JSON.parse(JSON.stringify(this.lineDtlsData));
      } else if (item.name === 'purReSupplier') {
        this.lineDtlsData[this.sepctIndex].supplier = item.vendor_name;
        this.lineDtlsData[this.sepctIndex].supplierCode = item.vendor_id;
        this.lineDtlsData[this.sepctIndex].site = null;
        this.lineDtlsData[this.sepctIndex].siteCode = null;
        this.lineDtlsData[this.sepctIndex].contact_person = null;
        this.lineDtlsData[this.sepctIndex].phone_number = null;
        this.lineDtlsData = JSON.parse(JSON.stringify(this.lineDtlsData));
      } else if (item.name === 'purReqSite') {
        this.lineDtlsData[this.sepctIndex].site = item.Supplier_Site_name;
        this.lineDtlsData[this.sepctIndex].siteCode = item.vendor_site_id;
        this.lineDtlsData[this.sepctIndex].contact_person = item.contact_person;
        this.lineDtlsData[this.sepctIndex].phone_number = item.phone_number;
        this.lineDtlsData = JSON.parse(JSON.stringify(this.lineDtlsData));
      } else if (item.name === 'purReqInvItem') {
        this.lineDtlsData[this.sepctIndex].category = item.item_category;
        this.lineDtlsData[this.sepctIndex].categoryCode = item.category_id;
        this.lineDtlsData[this.sepctIndex].item = item.item_code;
        this.lineDtlsData[this.sepctIndex].itemCode = item.item_id;
        this.lineDtlsData[this.sepctIndex].uom = item.primary_uom_code;
        this.lineDtlsData[this.sepctIndex].uom_meaning = item.unit_of_measure;
        this.lineDtlsData[this.sepctIndex].line_desc = item.description;
        this.lineDtlsData[this.sepctIndex].cost_center = null;
        this.lineDtlsData[this.sepctIndex].price = 0;
        this.lineDtlsData[this.sepctIndex].location = null;
        this.lineDtlsData[this.sepctIndex].locationCode = null;
        this.lineDtlsData[this.sepctIndex].organization = null;
        this.lineDtlsData[this.sepctIndex].orgCode = null;
        if (
          item.Inventory_Item_flag === 'Y' &&
          item.Inventory_Asset_flag === 'Y' &&
          item.Costing_Enabled_flag === 'Y'
        ) {
          this.lineDtlsData[this.sepctIndex].dest_type = 'Inventory';
        } else {
          this.lineDtlsData[this.sepctIndex].dest_type = 'Expense';
        }
        this.lineDtlsData = JSON.parse(JSON.stringify(this.lineDtlsData));
      } else if (item.name === 'purReqCostCenter') {
        this.lineDtlsData[this.sepctIndex].cost_center = item.flex_value;
        this.lineDtlsData[this.sepctIndex].price = 0;
        this.lineDtlsData[this.sepctIndex].location = null;
        this.lineDtlsData[this.sepctIndex].locationCode = null;
        this.lineDtlsData[this.sepctIndex].organization = null;
        this.lineDtlsData[this.sepctIndex].orgCode = null;
        this.getOrganizationData(item);
        this.getLocationDtlsData(item);
        this.getBuyerDtlsFromRealApp(
          this.lineDtlsData[this.sepctIndex].cost_center,
          this.lineDtlsData[this.sepctIndex].item
        );
        this.lineDtlsData = JSON.parse(JSON.stringify(this.lineDtlsData));
      } else if (item.name === 'purReqOrg') {
        // this.lineDtlsData[this.sepctIndex].organization =
        //   item.ORGANIZATION_NAME;
        // this.lineDtlsData[this.sepctIndex].orgId = item.ORGANIZATION_Id;
        // // this.getAvgPriceData();
        // this.lineDtlsData = JSON.parse(JSON.stringify(this.lineDtlsData));
      } else if (item.name === 'purReqLocation') {
        // this.lineDtlsData[this.sepctIndex].location = item.LOCATION_CODE;
        // this.lineDtlsData[this.sepctIndex].locationCode = item.location_id;
        // this.getAvgPriceData();
        // this.lineDtlsData = JSON.parse(JSON.stringify(this.lineDtlsData));
      } else if (item.name === 'purReqLineType') {
        this.lineDtlsData[this.sepctIndex].line_type = item.LINE_TYPE;
        this.lineDtlsData[this.sepctIndex].line_type_Code = item.LINE_TYPE_ID;
        this.lineDtlsData = JSON.parse(JSON.stringify(this.lineDtlsData));
      } else if (item.name === 'purReqHdrType') {
        this.reqHdrType = {
          text: item.description,
          value: item.lookup_code
        };
      } else if (item.name === 'purReqChargeAcc') {
        this.lineDtlsData[this.sepctIndex].charge_acc =
          item.concatenated_segments;
        this.lineDtlsData[this.sepctIndex].charge_acc_code =
          item.expense_account;
        this.getVarrienceAccData();
        this.getAccuralAccData();
      }
      this.showPurReqVsetModal = false;
    },
    getAvgPriceData(locId) {
      const payload = {
        organization_id: this.lineDtlsData[this.sepctIndex].orgId,
        item_id: this.lineDtlsData[this.sepctIndex].itemCode,
        location_id: locId
      };
      this.loader = true;
      this.$store
        .dispatch('purchaseRequisition/getAvgPriceData', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data.data.page;
            if (result.length) {
              this.lineDtlsData[this.sepctIndex].price = result[0].unit_price;
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getOrganizationData(item) {
      const payload = {
        flex_value: item.flex_value
      };
      this.loader = true;
      this.$store
        .dispatch('purchaseRequisition/getOrganizationData', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data.data.page;
            // this.purReqVsetData = result;
            // this.totalRows1 = resp.data.data.data.total_elements;
            this.lineDtlsData[this.sepctIndex].organization =
              result[0].ORGANIZATION_NAME;
            this.lineDtlsData[this.sepctIndex].orgId =
              result[0].ORGANIZATION_Id;
            // this.getAvgPriceData();
            this.lineDtlsData = JSON.parse(JSON.stringify(this.lineDtlsData));
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getLocationDtlsData(item) {
      const payload = {
        flex_value: item.flex_value
      };
      this.loader = true;
      this.$store
        .dispatch('purchaseRequisition/getLocationDtlsData', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data.data.page;
            // this.purReqVsetData = result;
            this.lineDtlsData[this.sepctIndex].location =
              result[0].LOCATION_CODE;
            this.lineDtlsData[this.sepctIndex].locationCode =
              result[0].location_id;
            this.getAvgPriceData(result[0].location_id);
            this.lineDtlsData = JSON.parse(JSON.stringify(this.lineDtlsData));
            this.totalRows1 = resp.data.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getBuyerDtlsFromRealApp(costCenter, itemCode) {
      if (costCenter && itemCode) {
        const payload = {
          cost_center: costCenter,
          item_code: itemCode
        };
        this.loader = true;
        this.$store
          .dispatch('purchaseRequisition/getBuyerDtlsFromRealApp', payload)
          .then(resp => {
            this.loader = false;
            if (resp.status === 200) {
              const result = resp.data.data.page;
              this.lineDtlsData[this.sepctIndex].buyer = result[0].buyer_name;
              this.lineDtlsData[this.sepctIndex].buyerCode = result[0].buyer_id;
              this.lineDtlsData = JSON.parse(JSON.stringify(this.lineDtlsData));
            }
          })
          .catch(() => {
            this.loader = false;
          });
      }
    },
    getVarrienceAccData() {
      const payload = {
        organization_id: this.lineDtlsData[this.sepctIndex].orgId
      };
      this.loader = true;
      this.$store
        .dispatch('purchaseRequisition/getVarrienceAccData', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data.data.page;
            if (result.length) {
              this.lineDtlsData[this.sepctIndex].varr_acc =
                result[0].invoice_price_var_account;
              this.lineDtlsData[this.sepctIndex].varr_acc_code =
                result[0].concatenated_segments;
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getAccuralAccData() {
      const payload = {
        organization_id: this.lineDtlsData[this.sepctIndex].orgId,
        destination_type_code: this.lineDtlsData[this.sepctIndex].dest_type
      };
      this.loader = true;
      this.$store
        .dispatch('purchaseRequisition/getAccuralAccData', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data.data.page;
            if (result.length) {
              this.lineDtlsData[this.sepctIndex].accural_acc =
                result[0].invoice_price_var_account;
              this.lineDtlsData[this.sepctIndex].accural_acc_code =
                result[0].concatenated_segments;
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getReqEmailData() {
      const payload = {
        global_name: this.lineDtlsData[this.sepctIndex].requester
      };
      this.loader = true;
      this.$store
        .dispatch('purchaseRequisition/getReqEmailData', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data.data.page;
            this.lineDtlsData[this.sepctIndex].requester_email =
              result[0].email_address;
            this.lineDtlsData[this.sepctIndex].reqEmailCode =
              result[0].person_id;
            this.lineDtlsData = JSON.parse(JSON.stringify(this.lineDtlsData));
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    addEditPurReqDtls() {
      const filterMandFields = this.lineDtlsData.filter(
        ele =>
          !ele.buyer ||
          !ele.cost_center ||
          !ele.requester ||
          ele.quantity == 0 ||
          ele.price == 0 ||
          !ele.need_by_date
      );
      if (!filterMandFields.length) {
        const addEditData = [];
        for (let i = 0; i < this.lineDtlsData.length; i++) {
          let itemA = this.lineDtlsData[i];
          let itemB = this.initialLineDtls[i];
          if (itemA.requisition_line_id == 0) {
            addEditData.push(itemA);
          } else if (
            itemA.requisition_line_id !== 0 &&
            itemA.requisition_line_id === itemB.requisition_line_id
          ) {
            if (
              itemA.line_type_Code !== itemB.line_type_Code ||
              itemA.item !== itemB.item ||
              itemA.itemCode !== itemB.itemCode ||
              itemA.category !== itemB.category ||
              itemA.categoryCode !== itemB.categoryCode ||
              itemA.line_desc !== itemB.line_desc ||
              itemA.uom !== itemB.uom ||
              itemA.uom_meaning !== itemB.uom_meaning ||
              itemA.cost_center !== itemB.cost_center ||
              itemA.orgId !== itemB.orgId ||
              itemA.organization !== itemB.organization ||
              itemA.price !== itemB.price ||
              itemA.quantity !== itemB.quantity ||
              itemA.need_by_date !== itemB.need_by_date ||
              itemA.buyer !== itemB.buyer ||
              itemA.buyerCode !== itemB.buyerCode ||
              itemA.req_by !== itemB.req_by ||
              itemA.requester !== itemB.requester ||
              itemA.requestorCode !== itemB.requestorCode ||
              itemA.requester_email !== itemB.requester_email ||
              itemA.supplier !== itemB.supplier ||
              itemA.supplierCode !== itemB.supplierCode ||
              itemA.site !== itemB.site ||
              itemA.siteCode !== itemB.siteCode ||
              itemA.contact_person !== itemB.contact_person ||
              itemA.phone_number !== itemB.phone_number
            ) {
              addEditData.push(itemA);
            }
          }
        }
        const data = addEditData.length
          ? addEditData.map(ele => {
              // if (ele.need_by_date == null) {
              //   this.nullDate++;
              // }
              return {
                requisition_line_id: ele.requisition_line_id
                  ? ele.requisition_line_id
                  : 0,
                line_type_id: ele.line_type_Code,
                line_type: ele.line_type,
                item_code: ele.item,
                item_id: ele.itemCode,
                item_category: ele.category,
                category_id: ele.categoryCode,
                description: ele.line_desc,
                primary_uom_code: ele.uom,
                unit_of_measure: ele.uom_meaning,
                destination_type_code: ele.dest_type,
                destination_type_meaning: ele.dest_type,
                price: +ele.price,
                quantity: +ele.quantity,
                need_by_date: ele.need_by_date,
                buyer_id: ele.buyerCode,
                buyer_name: ele.buyer,
                requested_by: ele.req_by,
                cost_center: ele.cost_center,
                requester_id: ele.requestorCode,
                requester_name: ele.requester,
                requester_email: ele.requester_email,
                organization_code: ele.orgId,
                organization_name: ele.organization,
                location_id: ele.locationCode,
                location: ele.location,
                distribution_details: {
                  distribution_id: ele.distribution_id
                    ? ele.distribution_id
                    : 0,
                  charge_account_id: ele.charge_acc_code,
                  charge_account_desciption: ele.charge_acc,
                  accural_account_id: ele.accural_acc,
                  accural_account_description: 'accural_account_description',
                  varrience_account_id: ele.varr_acc,
                  varrience_account_description: 'varrience_account_description'
                },
                supplier_id: ele.supplierCode,
                supplier_name: ele.supplier,
                supplier_site_id: ele.siteCode,
                supplier_site_name: ele.site,
                contact_person: ele.contact_person,
                phone: ele.phone_number
              };
            })
          : [];
        const payload = {
          purchasing_input_parameter: {
            purchasing_header_details: {
              requisition_header_id: this.reqHdrId ? this.reqHdrId : 0,
              ou_id: this.operatingUnit.value,
              ou_name: this.operatingUnit.text,
              description: this.description,
              requisition_type: this.reqHdrType.text,
              status: this.status.value,
              preparer_id: this.preparerId,
              preparer_name: this.preparer,
              amount: this.totalAmt,
              currency: this.currency,
              item_type: this.selectedItemType,
              purchasing_lines_details: data
            }
          }
        };
        // if (this.nullDate) {
        //   this.$bvToast.toast('Need By Date cannot be null', {
        //     title: 'Alert',
        //     variant: 'danger',
        //     solid: true
        //   });
        // } else {
        this.loader = true;
        this.$store
          .dispatch('purchaseRequisition/addEditPurReqDtls', payload)
          .then(response => {
            this.loader = false;
            if (response.status === 200) {
              // const result = response.data.data;
              this.editMode = false;
              this.reqHdrId = response.data.data;
              this.getPurReqDtlsById(response.data.data);
              this.$bvToast.toast(response.data.message, {
                title: 'Alert',
                variant: 'success',
                solid: true
              });
            } else {
              this.editMode = true;
              this.$bvToast.toast(response.response.data.message, {
                title: 'Alert',
                variant: 'danger',
                solid: true
              });
            }
          })
          .catch(() => {
            this.loader = false;
          });
        // }
        // this.nullDate = 0;
      } else {
        this.$bvToast.toast('Kindly fill all the mandatory(*) fields.', {
          title: 'Alert',
          variant: 'danger',
          solid: true
        });
      }
    },
    deleteReqLineData(item) {
      const promt = confirm(
        'Are you sure, you want to delete this line details?'
      );
      if (promt) {
        const payload = {
          requisition_line_id: item.requisition_line_id,
          requisition_header_id: this.reqHdrId
        };
        this.loader = true;
        this.$store
          .dispatch('purchaseRequisition/deleteReqLineData', payload)
          .then(response => {
            this.loader = false;
            if (response.status === 200) {
              this.$bvToast.toast(response.data.message, {
                title: 'Alert',
                variant: 'success',
                solid: true
              });
              this.getPurReqDtlsById();
            } else {
              this.editMode = true;
              this.$bvToast.toast(response.response.data.message, {
                title: 'Alert',
                variant: 'danger',
                solid: true
              });
            }
          })
          .catch(() => {
            this.loader = false;
          });
      }
    },
    getPurReqDtlsById(data) {
      const payload = {
        REQUISITION_HEADER_ID: data ? data : this.reqHdrId,
        user_id: this.$store.state.auth.userId
      };
      this.$store
        .dispatch('purchaseRequisition/getPurReqDtlsById', payload)
        .then(response => {
          this.loader = false;
          this.editMode = false;
          if (response.status === 200) {
            const result = response.data.data;
            this.wfApprovalStatus = result.approval_status_key;
            this.approvalStatusKey = result.approval_status_key;
            this.autoFillData(result);
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    autoFillData(item) {
      let x = 1;
      this.reqHdrId = item.REQUISITION_HEADER_ID;
      this.operatingUnit = {
        text: item.OU_NAME,
        value: item.ORG_ID
      };
      this.status = {
        text: item.APPROVAL_STATUS,
        value: item.APPROVAL_STATUS
      };
      this.description = item.DESCRIPTION;
      this.currency = item.CURRENCY;
      this.totalAmt = item.AMOUNT;
      this.selectedItemType = item.ITEM_TYPE;
      this.reqHdrType.text = item.REQUISITION_TYPE;
      this.reqHdrType.value = item.REQUISITION_TYPE;
      this.preparer = item.PREPARER_NAME;
      this.preparerId = item.PREPARED_ID;
      this.number = item.REQUISITION_NUMBER;
      const data = item.line_dtls.map(detail => {
        return {
          line_type: detail.LINE_TYPE,
          line_type_Code: detail.LINE_TYPE_ID,
          item: detail.ITEM_CODE,
          itemCode: detail.ITEM_ID,
          category: detail.ITEM_CATEGORY,
          categoryCode: detail.CATEGORY_ID,
          line_desc: detail.ITEM_DESCRIPTION,
          uom: detail.PRIMARY_UOM_CODE,
          uom_meaning: detail.UNIT_MEAS_LOOKUP_CODE,
          cost_center: detail.COST_CENTER,
          orgId: detail.ORGANIZATION_CODE,
          organization: detail.ORGANIZATION_NAME,
          price: detail.UNIT_PRICE,
          quantity: detail.QUANTITY,
          need_by_date: detail.NEED_BY_DATE,
          buyer: detail.SUGGESTED_BUYER_NAME,
          buyerCode: detail.SUGGESTED_BUYER_ID,
          dest_type: detail.DESTINATION_TYPE_CODE,
          charge_acc: detail.distribution_dtls.CHARGE_ACCOUNT_DESCRIPTION,
          charge_acc_code: detail.distribution_dtls.CHARGE_ACCOUNT_ID,
          varr_acc: detail.distribution_dtls.VARIANCE_ACCOUNT_ID,
          accural_acc: detail.distribution_dtls.ACCRUAL_ACCOUNT_ID,
          distribution_id: detail.distribution_dtls.DISTRIBUTION_ID,
          req_by: detail.REQUESTED_BY,
          requester: detail.REQUESTER_NAME,
          requestorCode: detail.REQUESTER_ID,
          requester_email: detail.REQUESTER_EMAIL,
          location: detail.LOCATION,
          locationCode: detail.DELIVER_TO_LOCATION_ID,
          supplier: detail.SUGGESTED_VENDOR_NAME,
          supplierCode: detail.VENDOR_ID,
          site: detail.SUGGESTED_VENDOR_LOCATION,
          siteCode: detail.VENDOR_SITE_ID,
          contact_person: detail.SUGGESTED_VENDOR_CONTACT,
          phone_number: detail.SUGGESTED_VENDOR_PHONE,
          requisition_line_id: detail.REQUISITION_LINE_ID,
          post_oracle_status: detail.POST_TO_ORACLE_STATUS_MEANING,
          post_oracle_message: detail.POST_TO_ORACLE_MESSAGE,
          num: x++
        };
      });
      this.initialLineDtls = data.length
        ? JSON.parse(JSON.stringify(data))
        : [];
      this.lineDtlsData = data.length
        ? data
        : [
            {
              num: 1,
              line_type: 'Goods',
              line_type_Code: 1,
              item: '',
              category: null,
              line_desc: null,
              uom: null,
              price: 0,
              need_by_date: null,
              quantity: 0,
              charge_acc: null,
              buyer: null,
              req_by: null,
              cost_center: null,
              requester: null,
              requester_email: null,
              organization: null,
              location: null,
              supplier: null,
              site: null,
              contact_person: null,
              phone_number: null,
              requisition_line_id: 0
            }
          ];
      if (this.status.text !== 'APPROVED') {
        this.lineDtlsFields = this.lineDtlsFields.filter(
          field =>
            field.key !== 'post_oracle_status' &&
            field.key !== 'post_oracle_message'
        );
      }
    },
    removeRow(index) {
      // if (this.edit) {
      this.lineDtlsData.splice(index, 1);
      // }
      this.lineDtlsData.map((ele, index) => {
        ele.num = index + 1;
      });
      this.calculateAmount();
    },
    disabledDates(date) {
      return commonHelper.disabledDates(date, this.creationDate);
    },
    validateEmail(data) {
      if (this.regexPattern.test(data) || data == '' || data == null) {
        this.emailFlag = false;
      } else {
        this.emailFlag = true;
      }
      if (this.emailFlag) {
        this.$bvToast.toast('Invalid Email Address', {
          title: 'Alert',
          variant: 'danger',
          solid: true
        });
      }
    },
    calculateAmount() {
      var count = 0;
      this.lineDtlsData.map(ele => {
        count += ele.quantity * ele.price;
      });
      this.totalAmt = count;
    },
    showHideApproveRejectModal(flag, type) {
      this.showApproveRejectModal = flag;
      this.approvRejectType = type;
    },
    approveRejectPurReq(status) {
      const payload = {
        event_id: this.rowData
          ? this.rowData.WF_EVENT_ID
          : this.wfPurReqDetails
          ? this.wfPurReqDetails.wf_event_id
          : null,
        wf_inprocess_dtl_id: this.rowData
          ? this.rowData.WF_INPROCESS_DTL_ID
          : this.wfPurReqDetails
          ? this.wfPurReqDetails.wf_inprocess_dtl_id
          : null,
        notification_status: status,
        notification_comments:
          status === 'REJECTED' ? this.rejectedReason : this.approvalReason
      };
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/approvalRejectSingle', payload)
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          if (response.status === 200) {
            // this.getVendorInvoiceDetailsById();
            this.editMode = false;
            this.isSuccess = true;
            // this.responseMsg = response.data.message;
            this.$bvToast.toast(response.data.message, {
              title: 'Alert',
              variant: 'success',
              solid: true
            });
            if (status === 'REJECTED') {
              this.showHideApproveRejectModal(false);
            } else if (status === 'APPROVED') {
              this.showHideApproveRejectModal(false);
            }
            this.getPurReqDtlsById(this.reqHdrId);
            // this.$emit('updateInvoice');
          } else {
            this.showAlert = false;
            alert(response.response.data.message);
            // this.isSuccess = false;
            // this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};

import commonHelper from '@/app/utility/common.helper.utility';
export default {
  name: 'purchaseReqVset',
  props: ['openModalType', 'openModalFormType', 'reqKeyFilter'],
  components: {},
  watch: {
    currentPage1: function() {
      this.getPurReqVsetData();
    },
    perPage1: function() {
      this.currentPage1 = 1;
      this.getPurReqVsetData();
    }
  },
  data() {
    return {
      loader: false,
      perPage1: commonHelper.perPageRecord,
      pageOptions1: commonHelper.getPageOption(),
      totalRows1: null,
      currentPage1: 1,
      purReqVsetFields: null,
      purReqVsetData: [],
      operatingUnitFields: [
        {
          key: 'ou_id',
          label: 'OU ID'
        },
        {
          key: 'ou_name',
          label: 'OU Name'
        }
      ],
      inventoryItemFields: [
        {
          key: 'item_id',
          label: 'Item Id'
        },
        {
          key: 'item_code',
          label: 'Inv Item'
        },
        {
          key: 'item_category',
          label: 'Category'
        },
        {
          key: 'description',
          label: 'Description'
        },
        {
          key: 'primary_uom_code',
          label: 'UOM'
        }
      ],
      requesterFields: [
        {
          key: 'person_id',
          label: 'Requester ID'
        },
        {
          key: 'global_name',
          label: 'Requester Name'
        }
      ],
      buyerFields: [
        {
          key: 'agent_id',
          label: 'Buyer ID'
        },
        {
          key: 'global_name',
          label: 'Buyer Name'
        }
      ],
      categoriesFields: [
        {
          key: 'category_id',
          label: 'Category ID'
          //   class: 'text-center'
        },
        {
          key: 'item_category',
          label: 'Categories'
          //   class: 'text-center'
        }
      ],
      supplierFields: [
        {
          key: 'vendor_id',
          label: 'Supplier ID'
        },
        {
          key: 'vendor_name',
          label: 'Supplier Name'
        }
      ],
      supplierSiteFields: [
        {
          key: 'vendor_site_id',
          label: 'Supplier Site ID'
        },
        {
          key: 'Supplier_Site_name',
          label: 'Supplier Site Name'
        },
        {
          key: 'contact_person',
          label: 'Contact Person'
        },
        {
          key: 'phone_number',
          label: 'Phone Number'
        }
      ],
      costCenterFields: [
        {
          key: 'flex_value',
          label: 'Cost Center'
        }
      ],
      oraganizationFields: [
        {
          key: 'ORGANIZATION_Id',
          label: 'Organization Id'
        },
        {
          key: 'ORGANIZATION_NAME',
          label: 'Organization Name'
        }
      ],
      locationFields: [
        {
          key: 'location_id',
          label: 'Location Id'
        },
        {
          key: 'LOCATION_CODE',
          label: 'Location'
        }
      ],
      reqLineTypeFields: [
        {
          key: 'LINE_TYPE_ID',
          label: 'Line Type Id'
        },
        {
          key: 'LINE_TYPE',
          label: 'Line Type'
        }
      ],
      chargeAccFields: [
        {
          key: 'expense_account',
          label: 'Account'
        },
        {
          key: 'concatenated_segments',
          label: 'Concatenated Segments'
        }
      ],
      reqEmailFields: [
        {
          key: 'person_id',
          label: 'Person Id'
        },
        {
          key: 'email_address',
          label: 'Email Address'
        },
        {
          key: 'global_name',
          label: 'Global Name'
        }
      ],
      vSetName: null,
      vSetNameItem: null,
      filterName: null,
      vendorId: null
    };
  },
  mounted() {
    if (this.openModalFormType) {
      // this.getPurReqVsetData();
      this.mapSearchByValue();
    }
  },
  methods: {
    getPurReqVsetData() {
      if (this.openModalType === 'operatingUnit') {
        this.getOperatingUnitData();
      } else if (this.openModalType === 'invItem') {
        this.getInventoryItemData();
      } else if (this.openModalType === 'requester') {
        this.getRequesterData();
      } else if (this.openModalType === 'reqEmail') {
        this.getReqEmailData();
      } else if (this.openModalType === 'buyer') {
        this.getBuyerData();
      } else if (this.openModalType === 'category') {
        this.getCategoriesData();
      } else if (this.openModalType === 'supplier') {
        this.getSupplierData();
      } else if (this.openModalType === 'supplierSite') {
        this.getSupplierSiteData();
      } else if (this.openModalType === 'costCenter') {
        this.getCostCenterData();
      } else if (this.openModalType === 'organization') {
        this.getOrganizationData();
      } else if (this.openModalType === 'location') {
        this.getLocationDtlsData();
      } else if (this.openModalType === 'reqLineType') {
        this.getReqLineTypeData();
      } else if (this.openModalType === 'reqHdrType') {
        this.getReqHdrTypeData();
      } else if (this.openModalType === 'chargeAcc') {
        this.getChargeAccData();
      }
    },
    mapSearchByValue() {
      if (this.openModalType === 'operatingUnit') {
        this.filterName = 'Search By OU Name';
        this.purReqVsetFields = this.operatingUnitFields;
        if (this.openModalFormType === 'purReqMainForm') {
          this.getOperatingUnitData();
        }
      } else if (this.openModalType === 'invItem') {
        this.filterName = 'Search By Item Description';
        this.purReqVsetFields = this.inventoryItemFields;
      } else if (this.openModalType === 'requester') {
        this.filterName = 'Search By Requester Name';
        this.purReqVsetFields = this.requesterFields;
      } else if (this.openModalType === 'reqEmail') {
        this.filterName = 'Search By Email Address';
        this.purReqVsetFields = this.reqEmailFields;
      } else if (this.openModalType === 'buyer') {
        this.filterName = 'Search By Buyer Name';
        if (this.openModalFormType === 'buyerMaster') {
          this.getBuyerData();
        }
        this.purReqVsetFields = this.buyerFields;
      } else if (this.openModalType === 'category') {
        this.filterName = 'Search By Categories';
        this.purReqVsetFields = this.categoriesFields;
      } else if (this.openModalType === 'supplier') {
        this.filterName = 'Search By Supplier Name';
        this.purReqVsetFields = this.supplierFields;
      } else if (this.openModalType === 'supplierSite') {
        this.filterName = 'Search By Supplier Site Name';
        this.purReqVsetFields = this.supplierSiteFields;
      } else if (this.openModalType === 'costCenter') {
        this.filterName = 'Search By Cost Center';
        if (this.openModalFormType === 'buyerMaster') {
          this.getCostCenterData();
        }
        this.purReqVsetFields = this.costCenterFields;
      } else if (this.openModalType === 'organization') {
        this.filterName = 'Search By Organization Name';
        this.purReqVsetFields = this.oraganizationFields;
      } else if (this.openModalType === 'location') {
        this.filterName = 'Search By Location';
        this.purReqVsetFields = this.locationFields;
      } else if (this.openModalType === 'reqLineType') {
        this.filterName = 'Search By Requisition Line Type';
        this.purReqVsetFields = this.reqLineTypeFields;
      } else if (this.openModalType === 'reqHdrType') {
        this.filterName = 'Search By Requisition Type';
        this.purReqVsetFields = this.reqHdrTypeFields;
      } else if (this.openModalType === 'chargeAcc') {
        this.filterName = 'Search By Charge Account';
        this.purReqVsetFields = this.chargeAccFields;
      }
    },
    rowSelectedpurchaseReq(item) {
      if (this.openModalType === 'operatingUnit') {
        item.name = 'purReqOU';
        if (this.openModalFormType === 'purReqEntryForm') {
          this.$emit('mapSelectedVsetData', item);
        } else if (this.openModalFormType === 'purReqMainForm') {
          this.$emit('mapSelectedVsetData', item);
        }
      } else if (this.openModalType === 'invItem') {
        if (this.openModalFormType === 'purReqEntryForm') {
          item.name = 'purReqInvItem';
          this.$emit('mapSelectedVsetData', item);
        } else if (this.openModalFormType === 'buyerMasterEntryForm') {
          item.name = 'buyMasInvItem';
          this.$emit('mapSelectedBuyerVsetData', item);
        }
      } else if (this.openModalType === 'requester') {
        item.name = 'purRequester';
        if (this.openModalFormType === 'purReqEntryForm') {
          this.$emit('mapSelectedVsetData', item);
        }
      } else if (this.openModalType === 'reqEmail') {
        item.name = 'purReqEmail';
        if (this.openModalFormType === 'purReqEntryForm') {
          this.$emit('mapSelectedVsetData', item);
        }
      } else if (this.openModalType === 'buyer') {
        if (this.openModalFormType === 'purReqEntryForm') {
          item.name = 'purReqBuyer';
          this.$emit('mapSelectedVsetData', item);
        } else if (this.openModalFormType === 'buyerMasterEntryForm') {
          item.name = 'buyMasBuyer';
          this.$emit('mapSelectedBuyerVsetData', item);
        } else if (this.openModalFormType === 'buyerMaster') {
          item.name = 'buyMasBuyer';
          this.$emit('mapSelectedBuyerVsetData', item);
        }
      } else if (this.openModalType === 'category') {
        item.name = 'purReqCategory';
        if (this.openModalFormType === 'purReqEntryForm') {
          this.$emit('mapSelectedVsetData', item);
        }
      } else if (this.openModalType === 'supplier') {
        item.name = 'purReSupplier';
        if (this.openModalFormType === 'purReqEntryForm') {
          this.$emit('mapSelectedVsetData', item);
        }
      } else if (this.openModalType === 'supplierSite') {
        item.name = 'purReqSite';
        if (this.openModalFormType === 'purReqEntryForm') {
          this.$emit('mapSelectedVsetData', item);
        }
      } else if (this.openModalType === 'costCenter') {
        if (this.openModalFormType === 'purReqEntryForm') {
          item.name = 'purReqCostCenter';
          this.$emit('mapSelectedVsetData', item);
        } else if (this.openModalFormType === 'buyerMasterEntryForm') {
          item.name = 'buyMasCostCenter';
          this.$emit('mapSelectedBuyerVsetData', item);
        } else if (this.openModalFormType === 'buyerMaster') {
          item.name = 'buyMasCostCenter';
          this.$emit('mapSelectedBuyerVsetData', item);
        }
      } else if (this.openModalType === 'organization') {
        item.name = 'purReqOrg';
        if (this.openModalFormType === 'purReqEntryForm') {
          this.$emit('mapSelectedVsetData', item);
        }
      } else if (this.openModalType === 'location') {
        if (this.openModalFormType === 'purReqEntryForm') {
          item.name = 'purReqLocation';
          this.$emit('mapSelectedVsetData', item);
        } else if (this.openModalFormType === 'buyerMasterEntryForm') {
          item.name = 'buyMasLocation';
          this.$emit('mapSelectedBuyerVsetData', item);
        }
      } else if (this.openModalType === 'reqLineType') {
        item.name = 'purReqLineType';
        if (this.openModalFormType === 'purReqEntryForm') {
          this.$emit('mapSelectedVsetData', item);
        }
      } else if (this.openModalType === 'chargeAcc') {
        item.name = 'purReqChargeAcc';
        if (this.openModalFormType === 'purReqEntryForm') {
          this.$emit('mapSelectedVsetData', item);
        }
      }
    },
    getOperatingUnitData() {
      const payload = {
        _page: this.currentPage1 - 1,
        _limit: this.perPage1,
        ou_name: this.vSetName
      };
      this.loader = true;
      this.$store
        .dispatch('purchaseRequisition/getOperatingUnitData', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data.data.page;
            this.purReqVsetData = result;
            this.totalRows1 = resp.data.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getInventoryItemData() {
      const payload = {
        _page: this.currentPage1 - 1,
        _limit: this.perPage1,
        item_description: this.vSetName,
        item_code: this.vSetNameItem,
        org_id:
          this.openModalFormType === 'buyerMasterEntryForm'
            ? '122'
            : this.reqKeyFilter.ouId,
        type: this.openModalFormType === 'buyerMasterEntryForm' ? 'BM' : 'PR',
        item_type: this.reqKeyFilter.itemType
      };
      this.loader = true;
      this.$store
        .dispatch('purchaseRequisition/getInventoryItemData', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data.data.page;
            this.purReqVsetData = result;
            this.totalRows1 = resp.data.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getRequesterData() {
      const payload = {
        _page: this.currentPage1 - 1,
        _limit: this.perPage1,
        global_name: this.vSetName
      };
      this.loader = true;
      this.$store
        .dispatch('purchaseRequisition/getRequesterData', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data.data.page;
            this.purReqVsetData = result;
            this.totalRows1 = resp.data.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getReqEmailData() {
      const payload = {
        _page: this.currentPage1 - 1,
        _limit: this.perPage1,
        global_name: this.vSetName
      };
      this.loader = true;
      this.$store
        .dispatch('purchaseRequisition/getReqEmailData', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data.data.page;
            this.purReqVsetData = result;
            this.totalRows1 = resp.data.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getBuyerData() {
      const payload = {
        _page: this.currentPage1 - 1,
        _limit: this.perPage1,
        global_name: this.vSetName
      };
      this.loader = true;
      this.$store
        .dispatch('purchaseRequisition/getBuyerData', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data.data.page;
            this.purReqVsetData = result;
            this.totalRows1 = resp.data.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getCategoriesData() {
      const payload = {
        _page: this.currentPage1 - 1,
        _limit: this.perPage1,
        Item_category: this.vSetName
      };
      this.loader = true;
      this.$store
        .dispatch('purchaseRequisition/getCategoriesData', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data.data.page;
            this.purReqVsetData = result;
            this.totalRows1 = resp.data.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getSupplierData() {
      const payload = {
        _page: this.currentPage1 - 1,
        _limit: this.perPage1,
        vendor_name: this.vSetName,
        org_id: this.reqKeyFilter.ouId
      };
      this.loader = true;
      this.$store
        .dispatch('purchaseRequisition/getSupplierData', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data.data.page;
            this.purReqVsetData = result;
            this.totalRows1 = resp.data.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getSupplierSiteData() {
      const payload = {
        _page: this.currentPage1 - 1,
        _limit: this.perPage1,
        site_name: this.vSetName,
        vendor_id: this.reqKeyFilter.vendorId,
        org_id: this.reqKeyFilter.ouId
      };
      this.loader = true;
      this.$store
        .dispatch('purchaseRequisition/getSupplierSiteData', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data.data.page;
            this.purReqVsetData = result;
            this.totalRows1 = resp.data.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getCostCenterData() {
      const payload = {
        _page: this.currentPage1 - 1,
        _limit: this.perPage1,
        flex_value: this.vSetName,
        org_id:
          this.openModalFormType !== 'buyerMasterEntryForm' ||
          this.openModalFormType !== 'buyerMaster'
            ? this.reqKeyFilter.ouId
            : null
      };
      this.loader = true;
      this.$store
        .dispatch('purchaseRequisition/getCostCenterData', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data.data.page;
            this.purReqVsetData = result;
            this.totalRows1 = resp.data.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getOrganizationData() {
      const payload = {
        _page: this.currentPage1 - 1,
        _limit: this.perPage1,
        organization_value: this.vSetName,
        flex_value: this.reqKeyFilter.cost_center
      };
      this.loader = true;
      this.$store
        .dispatch('purchaseRequisition/getOrganizationData', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data.data.page;
            this.purReqVsetData = result;
            this.totalRows1 = resp.data.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getLocationDtlsData() {
      const payload = {
        _page: this.currentPage1 - 1,
        _limit: this.perPage1,
        LOCATION_CODE: this.vSetName,
        flex_value: this.reqKeyFilter.cost_center
      };
      this.loader = true;
      this.$store
        .dispatch('purchaseRequisition/getLocationDtlsData', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data.data.page;
            this.purReqVsetData = result;
            this.totalRows1 = resp.data.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getReqLineTypeData() {
      const payload = {
        _page: this.currentPage1 - 1,
        _limit: this.perPage1,
        LINE_TYPE: this.vSetName
      };
      this.loader = true;
      this.$store
        .dispatch('purchaseRequisition/getReqLineTypeData', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data.data.page;
            this.purReqVsetData = result;
            this.totalRows1 = resp.data.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getReqHdrTypeData() {
      const payload = {
        _page: this.currentPage1 - 1,
        _limit: this.perPage1,
        LINE_TYPE: this.vSetName
      };
      this.loader = true;
      this.$store
        .dispatch('purchaseRequisition/getReqHdrTypeData', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data.data.page;
            this.purReqVsetData = result;
            const item = result[0];
            item.name = 'purReqHdrType';
            this.$emit('mapSelectedVsetData', item);
            this.totalRows1 = resp.data.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getAvgPriceData() {
      const payload = {
        _page: this.currentPage1 - 1,
        _limit: this.perPage1
      };
      this.loader = true;
      this.$store
        .dispatch('purchaseRequisition/getAvgPriceData', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data.data.page;
            this.purReqVsetData = result;
            const item = result[0];
            item.name = 'purReqHdrType';
            this.$emit('mapSelectedVsetData', item);
            this.totalRows1 = resp.data.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getChargeAccData() {
      let payload = {};
      if (this.reqKeyFilter.chargeAccType === 'Inventory') {
        payload = {
          _page: this.currentPage1 - 1,
          _limit: this.perPage1,
          destination_type_code: this.reqKeyFilter.chargeAccType,
          // inventory_item_id: this.reqKeyFilter.itemId,
          organization_id: this.reqKeyFilter.orgId
        };
      } else if (this.reqKeyFilter.chargeAccType === 'Expense') {
        payload = {
          _page: this.currentPage1 - 1,
          _limit: this.perPage1,
          destination_type_code: this.reqKeyFilter.chargeAccType,
          inventory_item_id: this.reqKeyFilter.itemId,
          organization_id: this.reqKeyFilter.orgId
        };
      }
      this.loader = true;
      this.$store
        .dispatch('purchaseRequisition/getChargeAccData', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data.data.page;
            this.purReqVsetData = result;
            const item = result[0];
            item.name = 'purReqHdrType';
            this.totalRows1 = resp.data.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    clearVsetValues(vsetCode) {
      if (vsetCode === this.vSetName) {
        this.vSetName = null;
      } else if (vsetCode === this.vSetNameItem) {
        this.vSetNameItem = null;
      }
    },
    clearPurReqVset() {
      this.vSetName = null;
      // this.getPurReqVsetData();
    }
  }
};
